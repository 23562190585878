const cargo_type = {
    name: 'cargo_type',
    title: 'Тип груза',
    url: '/refs/cargo_type',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.cargo_type.list',
        read: 'v1.cargo_type.read',
        create: 'v1.cargo_type.create',
        update: 'v1.cargo_type.update',
        delete: 'v1.cargo_type.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
};

export {
    cargo_type,
};
